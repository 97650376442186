import { Deserializable } from './deserializable.model';
import { CandidateModel } from './candidate.model';
import { EventModel } from './event.model';
import { SlotModel } from './slot.model';
import * as _moment from 'moment';
import { CANDIDATEEVENT_STATUSES, CANDIDATEEVENT_INTERVIEW_STATUSES, DATETIMES } from './constants';
import { JobtypeModel } from './jobtype.model';
import { JoblocationModel } from './joblocation.model';
import { JobModel } from './job.model';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export class CandidateEventModel implements Deserializable {
	// columns
	id: string;
	status: string;
	candidate_id: string;
	event_id: string;
	slot_id: string;
	job_id: string;
	recruiter_id: string;
	jobtype_id: string;
	joblocation_id: string;
	date_start: any;
	date_start_candidate: any;
	idnumber: number;
	status_history: string;
	interview_start: any;
	interview_end: any;
	interview_data: any;
	interview_result: string;
	interview_result_reason: string[];
	interview_circle: string;
	offerletter_signature_id: string;
	offerletter_signed: boolean | number;
	documentfolder: string;
	download_hash: string;
	slot_reminder: boolean;
	// misc
	job_profile_data: any;
	signature: any;
	profile_status: any;
	anonymized_at: any;
	misc: any;
	offerletter_content: string;
  xlsx: any;
  clickboarding_status: string | null;
	// relations parent
	candidate: CandidateModel = null;
	event: EventModel = null;
	slot: SlotModel = null;
	jobtype: JobtypeModel = null;
	joblocation: JoblocationModel = null;
	job: JobModel = null;

	deserialize(input: any): this {
		Object.assign(this, input);
		if (this.candidate !== null && this.candidate !== undefined) {
			this.candidate = new CandidateModel().deserialize(this.candidate);
		}
		if (this.event !== null && this.event !== undefined) {
			this.event = new EventModel().deserialize(this.event);
		}
		return this;
	}

	// relational data

	get event_name(): string {
		return (this.event !== null) ? this.event.name : '';
	}

	get event_cityzipstate(): string {
		return (this.event !== null) ? this.event.address_cityzipstate : '';
	}

	get event_date_full(): string {
		return (this.event !== null) ? this.event.date_full : '';
	}

	get event_date_short(): string {
		return (this.event !== null) ? this.event.date_short_dateonly : '';
	}

	get event_date_for_excel() {
		return (this.event !== null && this.event.date_start !== null) ? moment(this.event.date_start).toDate() : '';
	}

	get interview_time(): string {
		return (this.slot !== null) ? moment(this.slot.slot_time) : 'N/A';
	}

	get status_fullname(): string {
		let statusname = this.statusname;
		if (this.interviewresult !== 'N/A') {
			statusname += ' (' + this.interviewresult + ')';
		}
		return statusname;
	}

	get statusname(): string {
		return (this.status !== null && CANDIDATEEVENT_STATUSES.hasOwnProperty(this.status)) ? CANDIDATEEVENT_STATUSES[this.status] : 'N/A';
	}

	get interviewresult(): string {
		return (this.interview_result !== null && CANDIDATEEVENT_INTERVIEW_STATUSES.hasOwnProperty(this.interview_result)) ? CANDIDATEEVENT_INTERVIEW_STATUSES[this.interview_result] : 'N/A';
	}

	get interview_result_name(): string {
		return CANDIDATEEVENT_INTERVIEW_STATUSES[this.interview_result];
	}

	get jobtype_name() {
		return (this.jobtype !== null) ? this.jobtype.title : '';
	}

	get joblocation_name() {
		return (this.joblocation !== null) ? this.joblocation.address_city : '';
	}

	get eventtype_name() {
		return (this.event !== null) ? this.event.type : '';
	}

	get can_book_interview() {
		if (this.event.is_candidate_friday !== true) {
			return true;
		}
		if (this.interview_data === null || this.interview_data === undefined || !this.interview_data.hasOwnProperty('candidateData')) {
			return false;
		} else {
			return this.candidate.getProfileStatus() >= 99 && this.jobtype !== null && (this.competencesManatory() === false || this.interview_data.candidateData.competences.length > 0) && this.interview_data.candidateData.languages.length > 0;
		}
	}

	get date_start_candidate_human() {
		return (this.date_start_candidate !== null) ? moment(this.date_start_candidate).format(DATETIMES.DATE_LONG) : (this.date_start !== null) ? moment(this.date_start).format(DATETIMES.DATE_LONG) : '';
	}

	get date_start_for_excel() {
		return (this.date_start_candidate !== null) ? moment(this.date_start_candidate).toDate() : (this.date_start !== null) ? moment(this.date_start).toDate() : '';
	}

	get interview_reasons_human(): string {
		if (this.interview_result_reason !== null) {
			return this.interview_result_reason.join(', ');
		}
    return '';
	}

	get interview_start_human() {
		return (this.interview_start !== null) ? moment(this.interview_start).format(DATETIMES.DATETIME_LONG) : '';
	}

	get interview_start_for_excel() {
		return (this.interview_start !== null) ? moment(this.interview_start).toDate() : '';
	}

	get anonymized() {
		return this.anonymized_at !== null;
	}

	get interviewsummary_downloadable() {
		return this.interview_result !== null && this.anonymized === false;
	}

	get offerletter_downloadable() {
		return this.interview_result === 'hired' && this.anonymized === false;
	}

	get attentions() {
		const attentions = [];
		if (this.isPrivacypolicyReady() === false) {
			attentions.push('Privacy Policy was not accepted.');
		}
		return attentions;
	}

	get zoom_meeting_id() {
    return this.misc?.zoom?.id || null;
	}

	get zoom_candidate() {
		if (this.event.is_running && this.misc !== null && this.misc['zoom']['join_url']) {
			return this.misc['zoom']['join_url'];
		}
		return null;
	}

	get zoom_recruiter() {
		if (this.misc !== null && this.misc['zoom']['start_url']) {
			return this.misc['zoom']['start_url'];
		}
		return null;
	}

	get zoom_status() {
		if (this.misc !== null && this.misc['zoom']['status']) {
			return this.misc['zoom']['status'];
		}
		return null;
	}

	get zoom_recordings(): Array<any> | null {
		if (this.misc !== null && this.misc.zoom !== undefined && this.misc.zoom.recordings !== undefined) {
			const files: Array<any> = [];
			let count = 1;
			if (Array.isArray(this.misc.zoom.recordings)) {
				this.misc.zoom.recordings.forEach((recording: any) => {
					files.push({
						name: 'File ' + count,
						url: recording.download_url
					});
					count++;
				});
			}
			return files;
		}
		return null;
	}

	//

  isHired(): boolean {
    return this.interview_result === 'hired';
  }

  isFinished(): boolean {
    return this.status === 'finished';
  }

	getProfileStatus(): number {
		let value = 0;
		if (this.interview_data === null || this.interview_data === undefined || !this.interview_data.hasOwnProperty('candidateData')) {
			return value;
		}
		const data = this.interview_data.candidateData;
		if (this.event.is_searay) {
			value += 66;
		} else {
			if ((data.career !== null && data.career.length > 0) || data.nocareer === true) {
				value += 66;
			}
		}
		if ((this.competencesManatory() === false || data.competences.length > 0) && data.languages.length > 0) {
			value += 33;
		}
		return (value >= 99) ? 100 : value;
	}

	isPrivacypolicyReady() {
		return this.candidate.privacypolicy_id !== null && this.candidate.privacypolicy_at !== null;
	}

  isClickboardingFailed(): boolean {
    return this.clickboarding_status !== null && this.clickboarding_status.includes('error');
  }

  private competencesManatory(): boolean {
    return this.jobtype?.skills?.find((skill: any) => {
      return skill.mandatory === true;
    }) !== undefined;
  }

}

